$body-bg: #EBEFF3;

$theme-colors: (
  "primary" : #347DB0,
  "gray-light": #DFE3E7
);

$spacer: 1rem;
$spacers: (
  0: 0,
  1: ($spacer * .25),
  2: ($spacer * .5),
  3: $spacer,
  4: ($spacer * 1.5),
  5: ($spacer * 2),
  6: ($spacer * 3),
  7: ($spacer * 3.5),
  8: ($spacer * 4),
  9: ($spacer * 4.5),
  10: ($spacer * 5)
);

$font-weight-medium: 500;
$font-weight-semibold: 600;

@import "~bootstrap/scss/bootstrap";
@import "~react-datepicker/dist/react-datepicker.css";

// sidebar styles
.nav-link {
  border-left: 5px solid transparent;
  color: white;

  &.active {
    border-left: 5px solid white;
    background-color: black !important;

    path { fill: white; }
  }
}

// main table styles
.border-table.table {
  background-color: white;
  border-top: 5px solid theme-color("primary");

  // sort arrows
  th {
    position: relative;
    padding-right: map-get($spacers, 4);

    &:after, &:before {
      content: "";
      border: 5px solid transparent;
      position: absolute;
      right: map-get($spacers, 2);
    }

    &.sortable:after {
      border-bottom-color: silver;
      bottom: 50%;
      transform: translateY(-1px);
    }

    &.sortable:before {
      border-top-color: silver;
      top: 50%;
      transform: translateY(1px);
    }

    &.sort-desc {
      &:before { transform: translateY(-25%); }
      &:after { border-bottom-color: transparent; }
    }

    &.sort-asc {
      &:after { transform: translateY(25%); }
      &:before { border-top-color: transparent; }
    }
  }
}

// details table styles
.details-table.table {
  border-collapse: separate;
  border-spacing: 0 0.25rem;

  tr { height: 65px; }
  th, td { vertical-align: middle; }
  td:first-child { border-top-left-radius: 10px; border-bottom-left-radius: 10px; }
  td:last-child { border-top-right-radius: 10px; border-bottom-right-radius: 10px; }
}

// column filter styles
.btn-column-filter {
  border: 1px solid $gray-400;
}

.input-group > .input-group-append {
  > .dropdown > .btn {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  > .dropdown:not(:last-child) > .btn {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  > .dropdown:not(:first-child) > .btn {
    margin-left: -1px;
  }

  .dropdown-menu {
    min-width: 8rem;
  }
}

// bottom-bar styles
.bottom-bar {
  flex: 0 0;
  min-height: 64px;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
}

.asterisk::after {
  content: " *";
  color: red;
}

.icon-primary rect { fill: $primary; }

// custom
.font-size-sm { font-size: $font-size-sm }
.font-weight-medium { font-weight: $font-weight-medium; }
.font-weight-semibold { font-weight: $font-weight-semibold; }
.cursor-pointer { cursor: pointer; }
.border-gray-400 { border: 1px solid $gray-400; }

.min-height-400 {
  min-height: 600px;
}
